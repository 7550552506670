var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { col: "12", md: "6" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                  _c("strong", [_vm._v(" Bootstrap Collapse ")]),
                  _c("div", { staticClass: "card-header-actions" }, [
                    _c(
                      "a",
                      {
                        staticClass: "card-header-action",
                        attrs: {
                          href:
                            "https://coreui.io/vue/docs/components/collapse",
                          rel: "noreferrer noopener",
                          target: "_blank"
                        }
                      },
                      [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v("docs")
                        ])
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "CCardBody",
                [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.collapse = !_vm.collapse
                        }
                      }
                    },
                    [_vm._v(" Toggle Collapse ")]
                  ),
                  _c(
                    "CCollapse",
                    { staticClass: "mt-2", attrs: { show: _vm.collapse } },
                    [
                      _c(
                        "CCard",
                        { attrs: { "body-wrapper": "" } },
                        [
                          _c("CCardText", [_vm._v("Collapse contents Here")]),
                          _c(
                            "CButton",
                            {
                              attrs: { size: "sm", color: "secondary" },
                              on: {
                                click: function($event) {
                                  _vm.innerCollapse = !_vm.innerCollapse
                                }
                              }
                            },
                            [_vm._v(" Toggle Inner Collapse ")]
                          ),
                          _c(
                            "CCollapse",
                            {
                              staticClass: "mt-2",
                              attrs: { show: _vm.innerCollapse }
                            },
                            [
                              _c("CCard", { attrs: { "body-wrapper": "" } }, [
                                _vm._v("Hello!")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }